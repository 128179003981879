<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Senior Accountant
                                <router-link to="/careers/senior-accountant/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for an experienced <b>Senior Accountant</b> who is responsible for preparing and reviewing daily accounting operations, supporting the month-end close process, performing account reconciliations, supporting T&E, and helping to implement systems and process for the partner's growth. The person must ensure that the company is 100% compliant to all applicable US codes, laws, and regulations, including reportorial requirements and should be familiar with US Accounting Principles or GAAP, US taxation laws and has experience working with an international or US-based partner.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the part. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Perform month-end and quarter close procedures and ensure financials are in accordance with GAAP</li>
                                            <li><i class="icon-ok"></i>Prepare month-end journal entries, balance sheet reconciliations, and supporting schedules</li>
                                            <li><i class="icon-ok"></i>Serve as a back-up on month-end duties assigned to other team members</li>
                                            <li><i class="icon-ok"></i>Provide analysis, explain variances, and identify trends</li>
                                            <li><i class="icon-ok"></i>Proactively identify issues and areas for improvement</li>
                                            <li><i class="icon-ok"></i>Help establish and maintain policies and procedures and execute controls associated with areas of responsibility</li>
                                            <li><i class="icon-ok"></i>Support fixed assets, prepayments, T&E, credit cards, bank reconciliations, and other processes</li>
                                            <li><i class="icon-ok"></i>Assist with the annual audit process including both the readiness and execution, including preparation of supporting audit schedules</li>
                                            <li><i class="icon-ok"></i>Assist with and/or complete ad hoc projects as requested. Research and document issues, special projects, Audit requests, etc.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Qualifications
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Minimum of 4-5 years of experience in accounting functions with direct experience with corporate accounting and month end close processes</li>
                                            <li><i class="icon-ok"></i>Bachelor’s degree in accounting or finance, CPA or equivalent a plus</li>
                                            <li><i class="icon-ok"></i>Excellent organizational skills, enthusiastic, upbeat, and positive with the ability to work in a fast-paced, collaborative startup environment with changing priorities and deadlines</li>
                                            <li><i class="icon-ok"></i>Strong attention to detail and ability to research discrepancies and solve problems</li>
                                            <li><i class="icon-ok"></i>Must be a "self-starter" with a can-do demeanor and a serious desire to have a positive impact on the partner.</li>
                                            <li><i class="icon-ok"></i>Strong knowledge of U.S. GAAP and highly analytical</li>
                                            <li><i class="icon-ok"></i>Experience with general ledger functions and the month end/year end close process</li>
                                            <li><i class="icon-ok"></i>Gained direct accounting experience for any/all of the following areas:</li>
                                            <ul>
                                                <li>- Prepaids</li>
                                                <li>- Fixed Assets</li>
                                                <li>- Leases</li>
                                                <li>- Travel and Entertainment</li>
                                                <li>- Accruals</li>
                                                <li>- Operating expense and other GL Accounting</li>
                                                <li>- Account reconciliations</li>
                                                <li>- Consolidation</li>
                                            </ul>
                                            <li><i class="icon-ok"></i>Proven proficiency of Microsoft Office Suite (Word, PowerPoint, etc); must be highly proficient in Excel</li>
                                            <li><i class="icon-ok"></i>Excellent writing, oral communication, analytical, organizational, and interpersonal skills</li>
                                            <li><i class="icon-ok"></i>Ability to work in a fast paced environment, prioritize appropriately and handle multiple tasks simultaneously</li>
                                            <li><i class="icon-ok"></i>Excellent problem solving skills; critical thinker who can foresee potential obstacles and propose alternative approaches especially in situations where there is not a set way of doing things</li>
                                            <li><i class="icon-ok"></i>Experience with NetSuite, FloQast, and Jira considered a plus</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/senior-accountant/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>